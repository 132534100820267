/*----------------------------------------------------------------------------*\
    #SETTINGS PAGE
\*----------------------------------------------------------------------------*/

/**
 * Page (content) background color.
 */

$background-color:      $color-white;


/**
 * Contain.
 */

$contain-percentage:    6%;
