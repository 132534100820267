/*----------------------------------------------------------------------------*\
    #OBJECTS CONTAIN
\*----------------------------------------------------------------------------*/

/* .o-figure-image {} */

/**
 * Align single figure image to center.
 */

.o-figure-image--align-center {
    text-align: center;

    > * {
        margin-right: auto;
        margin-left: auto;
    }

    figcaption { text-align: left; }
}
